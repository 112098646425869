<template>
  <div class="home">
    <!-- Hero -->
    <hero2 v-if="false"></hero2>
    <!-- Serviços menu provisório -->
    <iconesApps v-if="false"></iconesApps>
    <!-- Menu categorias -->
    <menuCategorias :n_resultados="9" btn_ver_mais=""></menuCategorias>
    <!-- Livros Top lidos -->
    <div v-if="true" class="container-fluid content-wrap">
      <livrosDestaque></livrosDestaque>
    </div>
    <!-- Logos Bases de pesquisa-->
    <basesLogos></basesLogos>
    <!-- teste youtube -->
        <h2>Vídeos UNINOVE</h2>
        <div class="container my-4">
            <Splide :options="options">
                <Splide-slide v-for="video in videos" v-bind:key="video.id" class="livro">
                    <a :href="video.link_acesso">
                        <img :src="video.link_capa">
                    </a>
                </Splide-slide>
            </Splide>
        </div>
        <hr>
    <!-- Serviços biblioteca -->
    <barraServicos></barraServicos>

  </div>
</template>

<script>
// @ is an alias to /src
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import hero2 from '@/components/home/hero2.vue'
import livrosDestaque from '@/components/livrosDestaque.vue'
import barraServicos from '@/components/BarraServicos.vue'
import menuCategorias from '@/components/menuCategorias.vue'
import iconesApps from '@/components/iconesApps.vue'
import basesLogos from '@/components/basesLogos.vue'

export default {
    name: 'Home',
    components: {
        hero2,
        iconesApps,
        menuCategorias,
        livrosDestaque,
        barraServicos,
        basesLogos,
        Splide,
        SplideSlide,
    },
    data() {
		return {
			
			key_atualizacao: 0,
			options: {
				type: 'loop',
				pagination: false,
				perPage: 4,
				perMove: 1,
				gap: '1rem',
				breakpoints: {
					0: {
						perPage: 2,
					},
					350: {
						perPage: 3,
					},
					400: {
						perPage: 4,
					},
					576: {
						perPage: 4,
					},
					768: {
						perPage: 4,
					},
					992: {
						perPage: 4,
					},
					1200: {
						perPage: 4,
					}
				}
			}
		}
	},
    // methods: {
    //   searchPrimo: function(){
    //     document.getElementById("primoQuery").value = "any,contains," + document.getElementById("primoQueryTemp").value.replace(/[,]/g, " ");
    //     document.forms["searchForm"].submit();
    //   }
    // }
}
</script>

<style scoped>
.hero {
  background-color: #1961D3;
  padding: 40px 0 40px 0;
  max-height: 50vh;
}
.hero-text{
  text-align: left; 
  color:#fff; 
  font-weight: 800; 
  font-size: 4rem;
}
.container-fluid.content-wrap {
  max-width: 1320px;;
}

.search-bar {
  border: 1px solid 686868;
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  color: #2c3e50;
  top: -50px;
  box-shadow: var(--shadow) 0px 3px 8px;
}

form select{
  width: 100px !important;
  flex: none;
}

form input{
  width: 500px !important;
  flex: none;
}

.livro {
	transition: all .3s ease-in-out;
	padding: 10px 0;
}	
.livro:hover {
	transition: all .3s ease-in-out;
	-ms-transform: scale(1.4);
	-webkit-transform: scale(1.4);  
	transform: scale(1.1);
}
.livro img {  
	border-radius: 7px;
	box-shadow: var(--shadow) 0px 3px 8px;
	width: -webkit-fill-available;
}
.livro img:hover {  
	box-shadow: 0px 1px 5px 2px var(--shadow);
}
.categoria-header {
    align-items: center;
    padding-right: 0px !important;
}


@media screen and (max-width: 900px){
    .container-fluid {
      padding: 5px;
    }
    .hero {
      padding: 10px 0 10px 0;
      max-height: 30vh;
    }
    .hero-text{
      text-align: left; 
      color:#fff; 
      font-weight: 600; 
      font-size: 1.5rem;
    }
}
@media screen and (max-width: 500px){
    .container-fluid {
      padding: 7px;
    }
}
</style>

