<template>
    <div class="container">
        <!-- Menu com imagens no background -->
        <div v-if="bg_image" class="row">
            <div class="col-12 col-md-6 col-lg-4 p-2 p-lg-3" v-for="tag in tags.slice(0, n_resultados)" :key="tag.name" >
                <router-link :to="'curso=' + tag.path">
                    <div class="categoria-card" style='background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)), url("")'>
                        <!-- <div class="card-icon"><img :src="'https://www.perlego.com/images/subject_icons/' + tag.icon"></div> -->
                        <div class="card-text-bg-cover">{{tag.name}}</div>
                    </div>
                </router-link>
            </div>
        </div>

        <!-- Menu Padrão -->
        <div v-else class="row">
            <div class="col-12 col-md-6 col-lg-4 p-2 p-lg-3" v-for="tag in tags.slice(0, n_resultados)" :key="tag.name" >
                <router-link :to="'curso=' + tag.path">
                    <div class="categoria-card">
                        <div class="card-icon"><img :src="'https://www.perlego.com/images/subject_icons/' + tag.icon"></div>
                        <div class="card-text">{{tag.name}}</div>
                    </div>
                </router-link>
            </div>
        </div>
        
        <div v-if="btn_ver_mais" class="container mt-3 justify-content-center">
            <router-link class="btn btn-primary" to="/categorias">Ver todas as Categorias</router-link>
        </div>
        
    </div>
</template>

<script>
import tags_lista from '@/store/categorias.js'

export default {
  name: 'menuCategorias',
  props: {
      n_resultados: Number,
      btn_ver_mais: Boolean,
      bg_image: Boolean
  },
  data() {
      return {
        tags: tags_lista.lista // Pega a lista de tags de um arquivo js
      }
  }
}


</script>

<style scoped>
a {
    text-decoration: none;
}
.categoria-card {
    background-color: #fff;
    height: 60px;
    /* box-shadow: rgb(233 233 235 / 70%) 0px 2px 10px 5px; */
    border: 1px solid rgb(229, 229, 233); 
    font-size: 15px; 
    /* border-radius: 5px; */
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.categoria-card:hover {
    border: 1px solid #004588;
    cursor: pointer;
}
.card-icon{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 100%;
    width: 67px;
    padding: 0 15px 0 20px;
}
.card-text{
    font-weight: 100;
    color: #000;
    text-align: left;
}
.card-text-bg-cover{
    font-weight: 700;
    color: #fff;
    text-align: left;
    margin-left: 20px;
    font-size: 1.2rem;
}
.btn-primary{
	color: #6c757d;
	background: none;
	border-radius: 10px;
    border: 2px #6c757d solid;
}
.btn-primary:hover {
	color: #fff;
	background: #6c757d;
	border: 2px #6c757d solid;
}
@media screen and (min-width: 768px){
    .categoria-card {
      height: 90px ;
    }
}
</style>

