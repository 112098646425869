<template>
    <div class="row">
        <!-- icones bases -->
        <hr>
        <h2>Bases de dados</h2>
        <div class="row">
            <div v-for="img,index in bases" :key="index" class="col-6 col-md-4 col-lg-3 my-3">
                <img :src="img" style="height: 30px;" srcset="">
            </div>
        </div>
        <hr>
    </div>
</template>

<script>

export default {
  name: 'basesLogos',
  data() {
      return {
        bases: [
            'https://portalpadrao.ufma.br/site/noticias/revista-science-publica-artigo-sobre-pesquisa-coordenada-pela-ufma-sobre-o-cerrado-nordestino/SCIENCE.jpg',
            'https://osjournal.org/ojs/public/journals/1/homeHeaderTitleImage_en_US.png',
            'https://cdn-images-1.medium.com/max/800/1*H7Pq3wD3b7gHKQQwXZlSRg.jpeg',
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbbqxjN2JMc5XPvdaZ1FvpddVwxXdhjzcnRXUNJuiTxUoMj5_qgaYhRXHXi5h_TeqeeA&usqp=CAU',
            'https://www.pikpng.com/pngl/m/167-1678213_capes-logo-logo-da-capes-clipart.png',
            'https://www.research4life.org/wp-content/uploads/2021/07/cambridge-logo-transparent.png',
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_U4J7r1mMUWFF0r2eafvU73Hq1t9HOszZj1uJghyq4sGlQKIeGyvL2xsePRepOvTJaQ&usqp=CAU',
            'https://bce.unb.br/wp-content/uploads/2015/04/proquest_logo.jpg',
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUjvvp0dp_5qcN-zlFDXRWGj1pyUitAJeN9cRQFH9F8V_SZAi6Oz2QYyIKEY1cKGFYSB8&usqp=CAU',
            'https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/Thomson_Reuters_logo.svg/640px-Thomson_Reuters_logo.svg.png',
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCON0XNu7G-6soKU2bxLGUW2KpRKnJXDtJ7BCJu_LPdg43G5OZq7hcC4M-BDJ9GdFAkw&usqp=CAU',
            'https://www.franca.unesp.br/Home/sobre2108/biblioteca2888/apoioapesquisa3345/periodicos.png',
            'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwPcOHcwrdBWhrIykSr4XkMaCYvPQilRpbn82thgd0evwvwsrKekgSM1P2ynAn1NPPxA&usqp=CAU',
          ]
      }
  }
}


</script>

<style scoped>
a {
    text-decoration: none;
}
.categoria-card {
    background-color: #fff;
    height: 60px;
    /* box-shadow: rgb(233 233 235 / 70%) 0px 2px 10px 5px; */
    border: 1px solid rgb(229, 229, 233); 
    font-size: 15px; 
    /* border-radius: 5px; */
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.categoria-card:hover {
    border: 1px solid #004588;
    cursor: pointer;
}
.card-icon{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 100%;
    width: 67px;
    padding: 0 15px 0 20px;
}
.card-text{
    font-weight: 100;
    color: #000;
    text-align: left;
}
.card-text-bg-cover{
    font-weight: 700;
    color: #fff;
    text-align: left;
    margin-left: 20px;
    font-size: 1.2rem;
}
@media screen and (min-width: 768px){
    .categoria-card {
      height: 90px ;
    }
}
</style>

