<template>
  <div class="container-fluid mb-5" >
    <!-- <booksRowSwiper titulo="Livros mais lidos em seu curso:" sub_titulo="" categoria="livros.top_medicina"></booksRowSwiper> -->
    <!-- <booksRowSwiper titulo="Recomendados para o seu semestre" sub_titulo="1.200 livros encontrados" categoria="livros.medicina_sec_semestre"></booksRowSwiper> -->
    <slidejs formato="categoria" iconeCategoria="medicine-colour.svg" titulo="Medicina" sub_titulo="1.200 livros encontrados" categoria="medicina"></slidejs>
    <slidejs formato="categoria" iconeCategoria="law-colour.svg" titulo="Direito" sub_titulo="1.200 livros encontrados" categoria="direito"></slidejs>
    <slidejs formato="categoria" iconeCategoria="business-colour.svg" titulo="Engenharia Civil" sub_titulo="1.200 livros encontrados" categoria="engenharia-civil"></slidejs>
    <slidejs formato="categoria" iconeCategoria="medicine-colour.svg" titulo="Odontologia" sub_titulo="1.200 livros encontrados" categoria="odontologia"></slidejs>
    <slidejs formato="categoria" iconeCategoria="biological-sciences-colour.svg" titulo="Veterinária" sub_titulo="1.200 livros encontrados" categoria="medicina-veterinaria"></slidejs>
    <slidejs formato="categoria" iconeCategoria="computer-science-colour.svg" titulo="Ciência da Computacao" sub_titulo="1.200 livros encontrados" categoria="ciencia-da-computacao"></slidejs>
    <slidejs formato="categoria" iconeCategoria="education-colour.svg" titulo="Pedagogia" sub_titulo="1.200 livros encontrados" categoria="pedagogia"></slidejs>
    <slidejs formato="categoria" iconeCategoria="economics-colour.svg" titulo="Ciências Contábeis" sub_titulo="1.200 livros encontrados" categoria="ciencias-contabeis"></slidejs>
  </div>
</template>

<script>
import slidejs from '@/components/slidejs.vue'
// import tags_lista from '@/store/categorias.js'
// import firebase from "firebase/app";
// import 'firebase/firestore';

export default {
    name: 'livrosDestaque',
    components: {
        slidejs
    },
    // Quando crianos Data em um componente ele deve ser em formato de função
    data() {
        return {
            categorias: [
                {
                    
                }
            ]
        }
    }
}

    
</script>

<style scoped>
.teste {
    border: solid 1px red;
}

.badge{
    margin: 4px;
}
.badge:hover {
    transition: transform .3s;
    -ms-transform: scale(1.4);
    -webkit-transform: scale(1.4);  
    transform: scale(1.1);
}
</style>

